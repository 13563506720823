.cart {
  z-index: 101 !important;

  .search-product {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    height: 60px;
    width: 325px;
    background: #4d295b;
    color: #fff;
    font-size: 18px;
    text-transform: uppercase;
    float: left;
    margin-right: 6px;
    padding: 17px;
    text-align: center;
    font-weight: 300;
    letter-spacing: 2px;
  }
}
.input-group-text {
  background-color: #fafafa;
}
.slick-arrow.slick-next {
  z-index: 1;
}
.slick-arrow.slick-prev {
  z-index: 1;
}

