$link-hover: #3f3f3f;
$link-active: #4D295B;

//@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&family=Nunito:wght@200;300;400;500;600;700;800;900;1000&display=swap');
@import "cart";
@import '~bootstrap-icons/font/bootstrap-icons.css';
:root {
  --danger-color: #9b0202;
  --danger-color-rgb: 155,2,2;
  --vz-body-bg:#f2f2f2;
}

@font-face {
  font-family: 'effra';
  src: url('./Effra_Std_Rg.ttf') format('truetype');
}

@font-face {
  font-family: 'effra';
  src: url('./Effra_Trial_100.ttf') format('truetype');
  font-weight: 100; /* Peso da fonte bold */
}
@font-face {
  font-family: 'effra';
  src: url('./Effra_Trial_200.ttf') format('truetype');
  font-weight: 200; /* Peso da fonte bold */
}
@font-face {
  font-family: 'effra';
  src: url('./Effra_Trial_300.ttf') format('truetype');
  font-weight: 300; /* Peso da fonte bold */
}
@font-face {
  font-family: 'effra';
  src: url('./Effra_Trial_400.ttf') format('truetype');
  font-weight: 400; /* Peso da fonte bold */
}
@font-face {
  font-family: 'effra';
  src: url('./Effra_Trial_500.ttf') format('truetype');
  font-weight: 500; /* Peso da fonte bold */
}
@font-face {
  font-family: 'effra';
  src: url('./Effra_Trial_600.ttf') format('truetype');
  font-weight: 600; /* Peso da fonte bold */
}
@font-face {
  font-family: 'effra';
  src: url('./Effra_Trial_700.ttf') format('truetype');
  font-weight: 700; /* Peso da fonte bold */
}


@keyframes skeleton {
  100% {
    transform: translateX(100%);
  }
}

.skeleton {
  --base-color: var(--vz-body-bg);
  --highlight-color: #d9d9d9;
  --animation-duration: 1.5s;
  --animation-direction: normal;
  --pseudo-element-display: block; /* Enable animation */
  background-color:  var(--vz-body-bg);

  cursor:progress;
  width: 100%;
  border-radius: 0.25rem;
  display: inline-flex;
  line-height: 1;

  position: relative;
  user-select: none;
  overflow: hidden;
  z-index: 1; /* Necessary for overflow: hidden to work correctly in Safari */
}

.skeleton::after {
  content: ' ';
  display: var(--pseudo-element-display);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  background-repeat: no-repeat;
  background-image: linear-gradient(
                  90deg,
                  var(--base-color),
                  var(--highlight-color),
                  var(--base-color)
  );
  transform: translateX(-100%);

  animation-name: skeleton;
  animation-direction: var(--animation-direction);
  animation-duration: var(--animation-duration);
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
}

@media (prefers-reduced-motion) {
  .skeleton {
    --pseudo-element-display: none; /* Disable animation */
  }
}

.btn-flora-primary {
  background-color: $link-active;
  color: #fff;

}
.transition {
  display: none;

  &.show {
    display: block;
    animation: fadeIn 0.5s ease-in-out;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.form-label {
  color: #4d4d4d;
  font-weight: 500;
}

.h-25px {
  height: 25px;
}

.is-invalid-text {
  color: var(--danger-color) !important;
  font-size: 13px !important;

  padding-left: 2px !important;
  transition: opacity 0.3s ease-in-out !important;
  opacity: 0 !important;

  &.show {
    margin-top: 4px !important;
    opacity: 1 !important;
    transition: opacity 1s !important;
  }
}
.card {
  border: 1px solid #70707020
}
.root {

  .index {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 15px;
    border-radius: 50%;
    min-height: 30px;
    font-weight: 500;
    min-width: 30px;
    line-height: 30px;
  }
  .card-header {
    &:not(.completed):not(.in-progress) {
      border-bottom: none;
      .index {
        background-color: #ececec !important;
        color: #949494;
      }
      span {
        color:#858585;
      }
      small {
        color: #a9a9a9;
      }
    }
  }
  .in-progress {
    .index {
      background-color: #e9d6ef;
      color: #9c27b0 !important;
    }
    span,small {
      color: #9c27b0;
    }
  }
  .completed {

    .index {
      background-color:  #28a745;
      color: #fff !important;
    }
    span,small {
      color: #28a745;
    }
  }

  .button-edit {
    color: gray;
    font-size: 1rem;
  }

  .btn:focus {
    box-shadow: none;
  }
}

#root {
  h1,h2,h3,h4,h5,h6 {
    font-family: 'effra', sans-serif;
  }

  p, span,a {
    font-family: 'effra', sans-serif;
  }

  a:hover {
    color: $link-hover;
  }

  .hover-title {
    color: $link-active;
    transition: .1s all;
    &:hover {
      color: $link-hover
    }
  }
}

.most-saled {
  padding: 30px 0;


}

.limit-rows {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.clamp-1 {
  -webkit-line-clamp:1;
}

.clamp-2 {
  -webkit-line-clamp:2;
}
.clamp-3 {
  -webkit-line-clamp:3;
}

.clamp-4 {
  -webkit-line-clamp:4;
}
.text-dark {
  color: #3f3f3f !important;
}

.hideDots {
  button {
    visibility: hidden;
  }
}

.shadow {
  box-shadow: 0 .5rem 1rem rgba(0,0,0,.15)!important;
}

.shadow-hover:hover {
  transition: .2s all;
  box-shadow: 0 .5rem 1rem rgba(0, 0, 0, 0.07) !important;
}

.shadow-sm {
  box-shadow: 0 .125rem .25rem rgba(0,0,0,.075)!important;
}

.categories {
  padding-top: 95px;
  ul {
    li {
      cursor: pointer;
      padding: 10px;

      &:hover {
        background-color: rgba(211, 175, 223, 0.5);
        color: rgb(79, 42, 92);
      }
    }
    .active {
      background-color: rgba(211, 175, 223, 0.5);
      color: rgb(79, 42, 92);
      font-weight: 700;
    }
  }

  .productNew {
    a {
      color: inherit;
    }
    img {
      max-height: 200px;
      min-height: 200px;
      object-fit: cover;
      width: 100%;
      box-shadow: rgb(240 240 240 / 57%) 1px -1px 6px 4px;
    }

    p {
      margin-bottom: 0;
    }

    .price {
      font-size: 20px;
      font-weight: 600;
    }

    .price-from {
      font-size: 13px !important;
      text-decoration: line-through;
      color: #673ab7 !important;
    }

    .discount {
      font-size: 11px;
    }
    @media screen and (max-width: 767px) {
      img {
        height: 350px;
      }

      .price {
        font-size: 24px;
      }
    }
  }

  .product {
    a {
      color: inherit;
    }
    img {
      max-height: 450px;
      min-height: 450px;
      object-fit: cover;
      width: 100%;
      box-shadow: rgb(240 240 240 / 57%) 1px -1px 6px 4px;
    }

    h4 {
    }

    p {
      margin-bottom: 0;
    }

    .price {
      font-size: 20px;
      font-weight: 600;
    }

    .price-from {
      font-size: 13px !important;
      text-decoration: line-through;
      color: #673ab7 !important;
    }

    .discount {
      font-size: 11px;
    }
    @media screen and (max-width: 767px) {
      img {
        max-height: 250px;
        width: 250px;
        min-height: 250px;
      }
      .position-relative {
        display: flex;
        justify-content: center;
      }
      h4 {
        font-size: 20px !important;
      }
      .descip {
        font-size: 13px !important;
      }
      .sub-price {
        font-size: 14px !important;
      }
      .price {
        font-size: 19px !important;
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .categories {
    padding-top: 70px;
  }
}

@media screen and (max-width: 991px) {
  .categories {
    padding-top: 0;
  }
}

@media screen and (min-width: 1300px) {
  .categories {
    padding-top: 95px;
  }
}
.font-weight-super-light {
  font-weight: 200 !important;
}
.swiper-pagination-bullet-active {
  background: black !important;
}
.section-informative-banners {
  background-color: white;
  padding: 30px 0;
  .service-item {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 10px;
    margin-bottom: 40px;
    svg {
      width: 70px;
      height: 70px;
    }
  }

  @media screen and (max-width: 767px) {
    .container-items {
      gap: 70px;
    }
  }

  @media screen and (max-width: 1024px) {
    .container-items {
      justify-content: center;
      align-items: center;

    }
  }
}

.social-media-icon {
  gap: 1.5rem;
  transition: all 0.3s ease-in-out;
  .instagram {
    display: flex;
    background-color: white;
    justify-content: center;
    align-items: center;
    padding: 2.5rem;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    color: #c71e7e !important;
    transition: .3s all !important;

    i {
      font-size: 3rem;
    }

    transition: 3s all !important;
    background: linear-gradient(to right, #ff3019 0%, #c90477 100%);
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
    i {
      transition: .6s all !important;
      color: #fff;
    }
  }

  .facebook {
    display: flex;
    background-color: white;
    justify-content: center;
    align-items: center;
    padding: 2.5rem;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    transition: .3s all !important;

    i {
      font-size: 2.5rem;
    }
    background: linear-gradient(to bottom,  #286ed6, lighten( #286ed6, 20%));
    i {
      transition: .6s all !important;
      color: white;
    }
    &:hover {

    }
  }

  .small {
    width: 40px;
    height: 40px;
    padding: 1.6rem;

    i {
      font-size: 2rem;
    }
  }
}
@media (max-width: 575px) {
  .instagram-area-two {
    .col-6 {
      padding: 5px !important;
    }
  }
  .product-des-tab {
    .nav-link {
      padding: 5px;
    }
  }
}

  .whatsapp-fixed {
  position: fixed;
  bottom: 24px;
  right: 24px;
  z-index: 100;
  color: white !important;
  padding: 14px;
  background-color: #4dc247;
  box-shadow: 2px 2px 6px rgba(0,0,0,0.4);
  border-radius: 50%;

  &:hover {
    box-shadow: 2px 2px 6px rgba(0,0,0,0.7);
  }
}

.call-me {
  display: flex;
  justify-content: center;
  gap: 16px;
  align-items: center;
  cursor: pointer;
  margin: auto;
  text-align: center;
  background: #4dc247;
  color: white;
  padding: 10px 20px;
  border-radius: 10px;
  font-size: 18px;
  img {
    height: 30px;
    width: 30px;
  }
  &:hover {
    color: white !important;
    background-color: #48b042;
  }
}

.slider-products {
  background-color: #fdf9fd;
  transition: .5s all;
  .arrow {
    display: none;
    position: absolute;
    top: 0;
    bottom: 0;
    align-items: center;
    z-index: 9999;
    transition: .5s all;
    svg {
      cursor: pointer;
      color: #3c1241;
      transition: .3s all;
      font-size: 2rem;

      &:hover {
        transition: .5s all;
        color: #333;
      }
    }
  }

  .next {
    right: 0;
  }

  .swiper-pagination-bullet {
    background-color: black;
  }

  @media screen and (min-width: 767px) {
    .swiper-pagination-fraction, .swiper-pagination-custom, .swiper-horizontal > .swiper-pagination-bullets, .swiper-pagination-bullets.swiper-pagination-horizontal {
      margin-left: 32px;
    }
  }



  .swiper-product {
    align-items: center;
    justify-content: center;

    .swiper-image {
      max-width: 320px;
      width: 100%;
    }

    .swiper-title {
      font-weight: 900;
      font-size: 2rem;
      line-height: 30px;
      margin: 16px 0;
      text-align: center;
    }

    .swiper-description {
      font-size: 14px;
      text-align: center;
    }
  }

  &:hover {
    .arrow {
      transition: .5s all;
      display: flex;
    }
  }
}

.banner-differentials {
  background-color: #000;
  padding: 40px 0;

  img {
    max-width: 50px;
  }

  h2 {
    font-weight: 700;
  }

  h2, h6, small {
    color: white;
  }

  .option {
    min-height:190px;
  }

  @media screen and (min-width: 767px) {
    .option {
      min-height: 170px;
    }
  }
}

